<template>
  <div class="context_whole">
    <van-nav-bar
        style="background-color:rgb(48, 65, 86);"
        title="个人中心"

        @click-left="onClickLeft"
        @click-right="onClickRight"
    />

    <van-tabbar v-model="active" class="bottom"  inactive-color="black" active-color="blue">
      <van-tabbar-item  name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
      <!--<van-tabbar-item name="video" icon="video" to="/video">视频回放</van-tabbar-item>-->
      <!--<van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item>-->
      <van-tabbar-item  name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>

</template>

<script>
// import tabber from "../components/tabber";

export default {
  name: "user",
  components:{

  },
  data(){
    return{
      active:'user'
    }
  }
}
</script>

<style scoped>

</style>